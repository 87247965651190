.aboutPageWrapper {
    background: url('../Bg/aboutBg.png');
    background-color: rgba(0, 0, 0, 0.5);
    background-attachment: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.aboutPageTextWrapper {
    color: #fff;
    font-size: 3.5rem;
    letter-spacing: -2px;
}

.gray {
    color: rgb(221, 221, 221);
    font-size: 2rem;
}

.aboutPageTextWrapper strong:nth-child(11),
.aboutPageTextWrapper strong:nth-child(13) {
    font-size: 2.5rem;
}

.aboutPageTextWrapper button {
    background-color: #ff6500;
    border: none;
    border-radius: 100px;
    color: #fff;
    width: 30%;
    height: 7vh;
    font-size: 1.5rem;
    font-weight: 500;
    font-family: 'Pretendard';
    margin-top: 5%;
}

.aboutPageTextWrapper button:hover {
    cursor: pointer;
    background-color: #fff;
    color: #000;
}

.aboutSystemWrapper {
    background: url('../Bg/aboutBg2.jpg');
    width: 100%;
    height: 100vh;
    text-align: center;
    font-size: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
}

.aboutSystemWrapper strong {
    font-weight: 900;
}

.aboutSystemWrapper img {
    margin-top: 5%;
}

@media only screen and (max-width: 599px) {
    .aboutPageTextWrapper {
        font-size: 1.5rem;
    }
    .gray {
        font-size: 1rem;
    }
    .aboutPageTextWrapper strong:nth-child(11),
    .aboutPageTextWrapper strong:nth-child(13) {
        font-size: 1.2rem;
    }
    .aboutPageTextWrapper button {
        width: 50%;
    }
    .aboutSystemWrapper span {
        font-size: 1.7rem;
    }
    .aboutSystemWrapper strong {
        font-size: 2rem;
    }
    .aboutSystemWrapper img {
        width: 90%;
    }
    .aboutSystemWrapper {
        height: 50vh;
    }
    .aboutPageContainer {
        overflow-x: hidden;
      }
}