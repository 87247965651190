.portfolioWrapper {
  width: 100%;
}

.portfolioTextWrapper {
  width: 100%;
  text-align: center;
  line-height: 40px;
  margin-top: 12%;
  margin-bottom: 5%;
}

.portfolioTextWrapper span:nth-child(1) {
  font-size: 3rem;
  font-weight: 800;
}

.portfolioTextWrapper span:nth-child(3) {
  font-size: 1.7rem;
}

.portfolioContentWrapper1 {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.portfolioContent1,
.portfolioContent2,
.portfolioContent3 {
  width: 30%;
  text-align: left;
}

.portfolioContent1 img,
.portfolioContent2 img,
.portfolioContent3 img {
  width: 100%;
  margin-bottom: 3%;
  border-radius: 30px;
  transition: 0.3s;
}

.portfolioContent1 img:hover,
.portfolioContent2 img:hover,
.portfolioContent3 img:hover{
  cursor: pointer;
  filter: brightness(0.7);
}

.portfolioContentWrapper2 {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-top: 5%;
}

.portfolioContentWrapper3 {
    display: flex;
    justify-content: space-around;
    margin: 5% 0;
}

.portfolioContent1 span:nth-child(2),
.portfolioContent2 span:nth-child(2),
.portfolioContent3 span:nth-child(2) {
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 50px;
}

.portfolioContent1 span:nth-child(4),
.portfolioContent2 span:nth-child(4),
.portfolioContent3 span:nth-child(4) {
  font-size: 1.2rem;
}

@media only screen and (max-width: 599px) {
  .portfolioTextWrapper span:nth-child(1) {
    font-size: 2rem;
  }
  .portfolioTextWrapper span:nth-child(3) {
    font-size: 1.5rem;
  }
  .portfolioContentWrapper1,
  .portfolioContentWrapper2,
  .portfolioContentWrapper3 {
    display: block;
    width: 80%;
    margin: auto;
  }
  .portfolioContent1,
  .portfolioContent2,
  .portfolioContent3 {
    width: 100%;
    margin-top: 10%;
  }
  .portfolioContainer {
    overflow-x: hidden;
  }
}
