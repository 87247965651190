.footerWrapper {
    background-color: #000;
    display: flex;
    width: 100%;
    height: 25vh;
    align-items: center;
}

.footerLogo {
    margin-left: 3%;
}

.footerLogo:hover {
    cursor: pointer;
}

.footerTextWrapper {
    margin-left: 3%;
    width: 40%;
    line-height: 30px;
}

.footerTextWrapper span {
    color: #fff;
    margin: 0 15px;
}

.footerTextWrapper span:nth-child(1) {
    font-size: 1.5rem;
    font-weight: 700;
    margin-right: 50%;
}

.footerInsta {
    margin-right: 3%;
    width: 50px;
    height: 50px;
}

.footerKakao {
    width: 50px;
    height: 50px;
}

.footerTextFlex {
    display: flex;
    align-items: center;
    margin-bottom: 3%;
}

.footerKakao:hover {
    cursor: pointer;
}

.footerInsta:hover {
    cursor: pointer;
}
@media only screen and (min-width: 767px) and (max-width: 1367px) {
    .footerTextWrapper {
        width: 100%;
    }
    .footerTextWrapper span:nth-child(1) {
        margin-right: 5%;
    }
}

@media only screen and (max-width: 599px) {
    .footerWrapper {
        height: 100%;
        display: block;
    }
    .footerTextWrapper {
        width: 100%;
    }
    .footerTextWrapper span:nth-child(1) {
        margin-right: 10%;
    }
    .footerLogo img {
        margin-top: 5%;
        width: 10%;
    }
    .footerTextWrapper span {
        margin: 0;
    }
    .footerTextWrapper span:nth-child(2),
    .footerTextWrapper span:nth-child(3),
    .footerTextWrapper span:nth-child(5),
    .footerTextWrapper span:nth-child(7) {
        display: block;
    }
}