.contactWrapper {
    width: 80%;
    height: 100%;
    display: flex;
    margin: auto;
    justify-content: space-between;
    margin-bottom: 5%;
}


.contactInputWrapper form input {
    display: block;
    border: 2px solid #000;
}

.contactDescript {
    display: block;
}

.contactInputWrapper label {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 1%;
    margin-bottom: 3%;
}

.contactInputWrapper input {
    margin-bottom: 2%;
    margin-top: 1%;
}

.contactIdWrapper {
    margin-top: 5%;
}

.contactIdWrapper input {
    width: 50%;
    height: 5vh;
    border-radius: 50px;
    padding-left: 20px;
    font-size: 1.2rem;
}

.contactInputWrapper .contactRadioWrapper {
    display: flex;
    justify-content: left;
}

.contactInputWrapper .contactRadioWrapper label {
    display: flex;
}

.contactInputWrapper .contactRadioWrapper label:nth-child(2),
.contactInputWrapper .contactRadioWrapper label:nth-child(3),
.contactInputWrapper .contactRadioWrapper label:nth-child(4),
.contactInputWrapper .contactRadioWrapper label:nth-child(5),
.contactInputWrapper .contactRadioWrapper label:nth-child(6),
.contactInputWrapper .contactRadioWrapper label:nth-child(7) {
    margin-left: 3%;
}

.contactLeft {
    margin-top: 10%;
    width: 70%;
}

.contactTitle {
    font-size: 1.5rem;
    font-weight: 700;
    color: #6d6d6d;
}

.contactRight {
    width: 30%;
    height: 100%;
}

.contactTop {
    width: 100%;
    height: 53vh;
    background-color: #FDD900;
}

.contactBottom {
    width: 100%;
    height: 53vh;
    background-color: #20D7C5;
}

.contactInputWrapper span {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 5%;
}

.contactInputWrapper textarea {
    width: 50%;
    height: 30vh;
    border-radius: 15px;
    border: 2px solid #000;
    margin-top: 1%;
    resize: none;
    font-size: 1.2rem;
    padding-left: 10px;
    font-family: 'Pretendard';
}

.contactSubmit {
    width: 20%;
    height: 7vh;
    font-size: 1.2rem;
    font-weight: 700;
    border: 2px solid #000;
    background: none;
    transition: 0.3s;
    margin-top: 5%;
}

.contactSubmit:hover {
    background-color: #000;
    color: #fff;
    cursor: pointer;
}

@media only screen and (min-width: 1026px) and (max-width: 1367px) {
    .contactSubmit {
        width: 52%;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
    .contactInputWrapper textarea {
        width: 100%;
    }
    .contactIdWrapper input {
        width: 100%;
    }
    .contactSubmit {
        width: 100%;
    }
    .contactInputWrapper .contactRadioWrapper {
        display: block
    }
    .contactInputWrapper .contactRadioWrapper label:nth-child(2),
    .contactInputWrapper .contactRadioWrapper label:nth-child(3),
    .contactInputWrapper .contactRadioWrapper label:nth-child(4),
    .contactInputWrapper .contactRadioWrapper label:nth-child(5),
    .contactInputWrapper .contactRadioWrapper label:nth-child(6),
    .contactInputWrapper .contactRadioWrapper label:nth-child(7) {
        margin-left: 0;
    }
}

@media only screen and (max-width: 599px) {
    .contactIdWrapper input {
        width: 100%;
    }
    .contactRadioWrapper {
        flex-direction: column;
        margin-bottom: 10%;
    }
    .contactRadioWrapper input {
        margin-left: 0;
    }
    .contactInputWrapper .contactRadioWrapper label:nth-child(1) {
        margin-top: 10%;
    }
    .contactInputWrapper .contactRadioWrapper label:nth-child(2),
    .contactInputWrapper .contactRadioWrapper label:nth-child(3),
    .contactInputWrapper .contactRadioWrapper label:nth-child(4),
    .contactInputWrapper .contactRadioWrapper label:nth-child(5),
    .contactInputWrapper .contactRadioWrapper label:nth-child(6),
    .contactInputWrapper .contactRadioWrapper label:nth-child(7) {
        margin-left: 0;
    }
    .contactInputWrapper textarea {
        width: 100%;
    }
    .contactSubmit {
        width: 100%;
        margin-top: 10%;
    }
    .contactTitle {
        margin-top: 30%;
    }
    .contactContainer {
        overflow-x: hidden;
    }
}