.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  position: fixed;
  top: 0;
  padding: 1% 0;
  color: #fff;
  transition: background-color 0.5s, color 0.5s;
  z-index: 2;
}

.headerWrapper.scrolled {
  background-color: #fff;
  color: #000;
}

.logoWrapper {
  width: 50%;
  margin-left: 10%;
}

.logoWrapper img {
  width: 8%;
}

.logoWrapper img:hover {
  cursor: pointer;
}

.navWrapper {
  font-size: 1.3rem;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  margin-right: 10%;
}

.navWrapper span {
  margin: 0 3%;
  font-weight: 500;
}
.navWrapper .headerAbout,
.navWrapper .headerOff,
.navWrapper .headerContact {
  transition: 0.3s;
}

.navWrapper .headerAbout:hover,
.navWrapper .headerOff:hover,
.navWrapper .headerContact:hover {
  color: #c7c7c7;
  cursor: pointer;
}

.active {
  color: #000;
}

.mobileMenuWrapper {
  display: none;
}

.mobileNavWrapper {
  display: none;
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .logoWrapper img {
    width: 15%;
  }
  .mobileMenuWrapper {
    display: block;
  }
  .mobileMenuWrapper img {
    width: 50%;
  }
  .navWrapper {
    display: none;
  }
  .mobileNavWrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50%;
    background-color: #fff;
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
    transform: translateX(105%);
    transition: transform 0.3s ease-in-out;
    color: #000;
    text-align: center;
  }

  .mobileNavWrapper span {
    padding: 10% 0;
    border-bottom: 1px solid #bbbbbb;
    font-size: 1.5rem;
    font-family: 'Pretendard';
  }

  .mobileNavWrapper.mobileMenuOpen {
    transform: translateX(0);
  }
  .mobileNavWrapper img {
    width: 10%;
    margin-left: 85%;
    margin-top: 5%;
  }
}

@media only screen and (max-width: 599px) {
  .mobileNavWrapper img {
    width: 10%;
    margin-left: 85%;
    margin-top: 5%;
  }
  .logoWrapper img {
    width: 15%;
  }
  .mobileMenuWrapper {
    display: block;
  }
  .mobileMenuWrapper img {
    width: 30%;
    float: right;
    margin-right: 30%;
  }
  .navWrapper {
    display: none;
  }
  .mobileNavWrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50%;
    background-color: #fff;
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
    transform: translateX(105%);
    transition: transform 0.3s ease-in-out;
    color: #000;
    text-align: center;
  }

  .mobileNavWrapper span {
    padding: 10% 0;
    border-bottom: 1px solid #bbbbbb;
  }

  .mobileNavWrapper.mobileMenuOpen {
    transform: translateX(0);
  }
}
