.mainImgWrapper {
  background-image: url("../Bg/mainImg.png");
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.mainTextWrapper {
  margin-left: 10%;
  font-size: 3.5rem;
}

.mainTextWrapper span:nth-child(1) {
  font-weight: 200;
  letter-spacing: -1px;
}

.mainTextWrapper span:nth-child(3) {
  font-weight: 700;
  display: block;
}

.mainButton {
  background: none;
  border: 2px solid #000;
  padding: 4% 5.5%;
  font-size: 1.2rem;
  margin-top: 5%;
  transition: 0.3s;
  font-weight: 700;
}

.mainButton:hover {
  background-color: #fee923;
  border: 2px solid #000;
  color: #000;
  cursor: pointer;
}

.mainContentContainer {
  width: 100%;
  height: 100vh;
}

.mainContentWrapper1 {
  background-color: #f7f7f7;
}

.mainContentWrapper1,
.mainContentWrapper2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 50vh;
  text-align: center;
}

.mainContentWrapper1 img,
.mainContentWrapper2 img {
  width: 100%;
}

.mainContentTextWrapper {
  margin-top: 7%;
}

.mainContentTextWrapper span:nth-child(1) {
  font-size: 1.2rem;
}

.mainContentTextWrapper span:nth-child(2) {
  font-size: 1.5rem;
  font-weight: 700;
}

.aboutWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 50vh;
  background-color: #505050;
}

.aboutTextWrapper span:nth-child(3) {
  font-weight: 700;
}

.aboutTextWrapper div span:nth-child(1),
.aboutTextWrapper div span:nth-child(3),
.aboutTextWrapper div span:nth-child(4) {
  font-size: 3rem;
  color: #fff;
}

.aboutTextWrapper div:nth-child(2) {
  margin-top: 10%;
}

.aboutTextWrapper div:nth-child(2) span:nth-child(1) {
  font-size: 1.5rem;
  color: #fff;
}

.contactButton {
  display: block;
  background: none;
  border: 2px solid #fff;
  color: #fff;
  padding: 3% 10%;
  margin-top: 10%;
  font-size: 1.2rem;
  font-weight: 700;
  transition: 0.3s;
}

.contactButton:hover {
  cursor: pointer;
  background-color: #fff;
  color: #000;
}

.projectWrapper {
  width: 100%;
  height: 100vh;
}

.projectTitleWrapper {
  text-align: center;
  margin-top: 5%;
}

.projectTitleWrapper span {
  color: #7a7a7a;
  font-size: 2rem;
  font-weight: bold;
}

.projectTitleWrapper p {
  font-size: 2rem;
  font-weight: 900;
  margin-top: 1%;
}

.projectContentWrapper1 {
  display: flex;
  margin: auto;
  width: 80%;
  height: 40vh;
}

.projectContentWrapper1 .apartPj,
.projectContentWrapper1 .busPj,
.projectContentWrapper1 .busStopPj,
.projectContentWrapper2 .displayPj,
.projectContentWrapper2 .subwayPj,
.projectContentWrapper2 .cinemaPj,
.projectContentWrapper2 .oohPj {
  position: relative;
  cursor: pointer;
}

.projectContentWrapper1 .apartPj:hover::before,
.projectContentWrapper1 .busPj:hover::before,
.projectContentWrapper1 .busStopPj:hover::before,
.projectContentWrapper2 .displayPj:hover::before,
.projectContentWrapper2 .subwayPj:hover::before,
.projectContentWrapper2 .cinemaPj:hover::before,
.projectContentWrapper2 .oohPj:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* 어둡게 변하는 배경색 및 투명도 조절 */
  z-index: 1;
  pointer-events: none; /* 이벤트 전파 방지 */
}

.projectContentWrapper1 .apartPj span,
.projectContentWrapper1 .busPj span,
.projectContentWrapper1 .busStopPj span,
.projectContentWrapper2 .displayPj span,
.projectContentWrapper2 .subwayPj span,
.projectContentWrapper2 .cinemaPj span,
.projectContentWrapper2 .oohPj span {
  z-index: 2; /* 텍스트를 배경 위에 표시 */
}

.projectContentWrapper1 .apartPj p,
.projectContentWrapper1 .busPj p,
.projectContentWrapper1 .busStopPj p,
.projectContentWrapper2 .displayPj p,
.projectContentWrapper2 .subwayPj p,
.projectContentWrapper2 .cinemaPj p,
.projectContentWrapper2 .oohPj p {
  z-index: 2; /* 부가 설명 텍스트를 배경 위에 표시 */
}

.projectContentWrapper1 div,
.projectContentWrapper2 div {
  width: 33.33%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.projectContentWrapper1 div span,
.projectContentWrapper2 div span {
  font-size: 2rem;
  font-weight: 700;
  display: none;
}

.projectContentWrapper1 div p,
.projectContentWrapper2 div p {
  font-size: 1.2rem;
  width: 70%;
  margin-top: 5%;
  display: none;
}

.projectContentWrapper2 {
  display: flex;
  margin: auto;
  width: 80%;
  height: 30vh;
}

.projectContentWrapper2 div {
  width: 25%;
}

.apartPj:hover span {
  filter: none;
  color: #fff;
}

.apartPj {
  background: url("../Bg/project1.jpg");
  background-position: center;
  background-size: cover;
}

.apartPj:hover span,
.busPj:hover span,
.busStopPj:hover span,
.displayPj:hover span,
.subwayPj:hover span,
.cinemaPj:hover span,
.oohPj:hover span {
  display: block;
}

.apartPj:hover p,
.busPj:hover p,
.busStopPj:hover p,
.displayPj:hover p,
.subwayPj:hover p,
.cinemaPj:hover p,
.oohPj:hover p {
  display: block;
}

.busPj {
  background: url("../Bg/project6.jpg");
  background-position: center;
  background-size: cover;
}
.busStopPj {
  background: url("../Bg/project2.jpg");
  background-position: center;
  background-size: cover;
}
.displayPj {
  background: url("../Bg/project5.jpg");
  background-position: center;
  background-size: cover;
}
.subwayPj {
  background: url("../Bg/project7.jpg");
  background-position: center;
  background-size: cover;
}
.cinemaPj {
  background: url("../Bg/project3.jpg");
  background-position: center;
  background-size: cover;
}
.oohPj {
  background: url("../Bg/project4.jpg");
  background-position: center;
  background-size: cover;
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
  .mainContentContainer {
    display: none;
  }
  .aboutWrapper {
    display: block;
    height: 100%;
  }
  .aboutTextWrapper div {
    width: 100%;
    padding-top: 5%;
    padding-left: 5%;
  }
  .aboutTextWrapper div:nth-child(2) {
    margin-top: 0;
  }
  .aboutTextWrapper .contactButton {
    margin-top: 5%;
  }
  .aboutImgWrapper img {
    margin-top: 10%;
    margin-left: 3%;
  }
  .projectContentWrapper1 {
    display: block;
    width: 100%;
  }
  .projectContentWrapper {
    height: 160%;
  }
  .projectContentWrapper1,
  .projectContentWrapper2 {
    display: block;
    width: 100%;
  }
  .projectContentWrapper1 .apartPj,
  .projectContentWrapper1 .busPj,
  .projectContentWrapper1 .busStopPj,
  .projectContentWrapper2 .displayPj,
  .projectContentWrapper2 .subwayPj,
  .projectContentWrapper2 .cinemaPj,
  .projectContentWrapper2 .oohPj {
    width: 100%;
    height: 100%;
  }
  .contactRight {
    display: none;
  }
  .navWrapper {
    display: none;
  }
  .contactContainer {
    width: 100%;
  }
  .projectContentWrapper1 div p,
  .projectContentWrapper2 div p {
    font-size: 1rem;
  }
  .projectContentWrapper1 div span,
  .projectContentWrapper2 div span {
    font-size: 1.7rem;
  }
}

@media only screen and (max-width: 599px) {
  .mainContainer {
    overflow-x: hidden;
  }
  .mainTextWrapper {
    font-size: 2rem;
  }
  .mainContentContainer {
    width: 100%;
    height: 100%;
  }
  .mainContentWrapper1,
  .mainContentWrapper2 {
    display: none;
  }
  .aboutTextWrapper div {
    width: 100%;
    padding-top: 10%;
    padding-left: 10%;
  }
  .aboutTextWrapper div:nth-child(2) {
    margin-top: 0;
  }
  .aboutTextWrapper div span:nth-child(1),
  .aboutTextWrapper div span:nth-child(3),
  .aboutTextWrapper div span:nth-child(4) {
    font-size: 1.8rem;
  }
  .aboutTextWrapper div:nth-child(2) span:nth-child(1) {
    font-size: 1rem;
  }
  .aboutImgWrapper img {
    width: 100%;
    margin-top: 10%;
  }
  .aboutWrapper {
    height: 100%;
    display: block;
  }
  .projectContentWrapper {
    height: 160%;
  }
  .projectContentWrapper1,
  .projectContentWrapper2 {
    display: block;
    width: 100%;
  }
  .projectContentWrapper1 .apartPj,
  .projectContentWrapper1 .busPj,
  .projectContentWrapper1 .busStopPj,
  .projectContentWrapper2 .displayPj,
  .projectContentWrapper2 .subwayPj,
  .projectContentWrapper2 .cinemaPj,
  .projectContentWrapper2 .oohPj {
    width: 100%;
    height: 100%;
  }

  .contactRight {
    display: none;
  }
  .navWrapper {
    display: none;
  }
  .contactContainer {
    width: 100%;
  }
  .projectContentWrapper1 div p,
  .projectContentWrapper2 div p {
    font-size: 1rem;
  }
  .projectContentWrapper1 div span,
  .projectContentWrapper2 div span {
    font-size: 1.7rem;
  }
}
