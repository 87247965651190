.adWrapper {
    margin-top: 10%;
    text-align: center;
}

.adWrapper span {
    font-size: 3rem;
    font-weight: 700;
}

.adWrapper1,
.adWrapper2,
.adWrapper3 {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2%;
}
.adWrapper3 {
    margin-bottom: 5%;
}

.adWrapper1 img,
.adWrapper2 img,
.adWrapper3 img {
    width: 23%;
    height: 35vh;
    border-radius: 20px;
}

.adWrapper1 {
    margin-top: 5%;
}
@media only screen and (min-width: 1026px) and (max-width: 1367px) {
    .adWrapper1,
    .adWrapper1 img,
    .adWrapper2,
    .adWrapper2 img,
    .adWrapper3,
    .adWrapper3 img {
        display: block;
        width: 90%;
        height: 100%;
        margin: auto;
    }
    .adWrapper img {
        margin-top: 10%;
        margin-bottom: 10%;
    }
}

@media only screen and (max-width: 599px) {
    .adWrapper span {
        font-size: 1.5rem;
    }
    .adWrapper {
        margin-top: 20%;
    }
    .adWrapper1,
    .adWrapper1 img,
    .adWrapper2,
    .adWrapper2 img,
    .adWrapper3,
    .adWrapper3 img {
        display: block;
        width: 90%;
        margin: auto;
    }
    .adWrapper img {
        margin-top: 10%;
        margin-bottom: 10%;
    }
}